import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTAnnotationViewController annotationForIndexPath:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPolylineCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTPolylineCreate.vertices</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, nullable) NSArray&lt;NSValue *&gt; *vertices</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nullable) NSArray&lt;NSValue *&gt; *vertices</td></tr>
</table>
<br>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}